import React, { useState } from 'react';
import { Outlet, NavLink } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AskAI } from './askai'
import { PromptAI } from './prompt'
import { SearchTemplates } from './searchtemplates'
import { Templates } from './templatefaq'
import { Integrations } from './integrations'
import { Nav } from 'react-bootstrap'
import './routing.css'

function RootLayout({cost})
{
    return (
	<div className="mt-2 container-fluid border rounded h-100">
	    <div class="d-flex justify-content-between align-items-center">
		<span className="text-danger"
		      dangerouslySetInnerHTML={{__html: cost}}></span>
		<Nav variant="tabs" activeKey="/">
		    <Nav.Item className="me-2">
			<NavLink to="/">AskAI</NavLink>
		    </Nav.Item>
		    <Nav.Item className="me-2">
			<NavLink to="/r/search/">Search</NavLink>
		    </Nav.Item>
		    <Nav.Item className="me-2">
			<NavLink to="/r/templates/">Templates</NavLink>
		    </Nav.Item>
		    <Nav.Item className="me-2">
			<NavLink to="/r/integrations/">Integrations</NavLink>
		    </Nav.Item>
		    <Nav.Item className="me-2">
			<NavLink to="/r/prompt/">Prompts</NavLink>
		    </Nav.Item>
		</Nav>
	    </div>
	    <Outlet/>
	</div>
    )
}

function PageNotFound({})
{
    return (
	<div>Page not found here</div>
    )
}

export function Routing({})
{
    const [cost, setCost] = useState('')
    return (
	<BrowserRouter>
	    <Routes>
		<Route path="/" element={<RootLayout cost={cost}/>}>
		    <Route index element={<AskAI setCost={setCost}/>}/>
		    <Route path="r/search" element={<SearchTemplates/>}/>
		    <Route path="r/templates" element={<Templates setCost={setCost}/>}/>
		    <Route path="r/integrations" element={<Integrations setCost={setCost}/>}/>
		    <Route path="r/prompt" element={<PromptAI/>}/>
		    <Route path="*" element={<PageNotFound />} />
		</Route>
	    </Routes>
	</BrowserRouter>
    )
}
