import React, { useRef, useState, useEffect } from 'react';
import { getCookieValue } from './utils';
import { Modal, Button, Tab, Tabs, Accordion, Dropdown } from 'react-bootstrap';
import { LinkToggle, DropSearchMenu } from './dropdownwithmenu';
import { IconBxChevronRight, IconBxChevronLeft, IconOpenInNewWindow } from './icons'
import dayjs from 'dayjs';

function DropdownItemList({label, items, onItemSelected, itemFunc})
{
    return (
	<Dropdown>
	    <Dropdown.Toggle as={LinkToggle}>
		<span class="me-1">{label}</span>
		<span style={{color: 'var(--bs-success)', fontSize: '0.8rem'}}>[{items.length}]</span>
	    </Dropdown.Toggle>
	    <Dropdown.Menu as={DropSearchMenu}
			   style={{maxHeight: '400px',
				   overflowY: 'auto',
				   overflowX: 'hidden'}}>
		{items.map((item, ii) => (
		    <>
			<Dropdown.Item eventKey={ii+1}
				       onClick={ev => onItemSelected(item.id)}>
			    {itemFunc(item)}
			</Dropdown.Item>
		    </>
		))}
	    </Dropdown.Menu>
	</Dropdown>
    )
}

function ItemDetail({item})
{
    return (
	<>
	    {item.id}.
	    {item.provider_name}
	    <span class="ms-2"
		  style={{fontSize: '0.8rem', cursor: 'pointer'}}>
		[{dayjs(item.updated_dt).format("MMM DD, YYYY")}]
	    </span>
	</>
    )
}

function AddNewFaq({integrations, selectedInteg, setCost})
{
    const [question, setQuestion] = useState(''),
	  [answer, setAnswer] = useState(''),
	  [htmlAnswer, setHtmlAnswer] = useState(''),
	  [context, setContext] = useState('Try to use a business tone throughout'),
	  [canEditContext, setCanEditContext] = useState(false),
	  askQuestion = ev => {
	      const fd = new FormData()
	      fd.append('question', question)
	      fd.append('context', context)
	      setCost('')
	      fetch('/api/askaifaq/', {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('askaifaq: %O', json)
		      setAnswer(json.content)
		      setCost((Math.round(json.total_cost * 10000)/10000).toFixed(4) + ' &cent;')
		  })
		  .catch(r => {
		      console.error('error in askaifaq: %O, %O', r.status, r.statusText)
		  })
	  },
	  showMarkdown = tabName => {
	      if ( tabName !== 'preview' ) return
	      const fd = new FormData()
	      fd.append('markdown', answer)
	      fetch('/api/md2html/', {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('md2html: %O', json)
		      setHtmlAnswer(json.html)
		  })
		  .catch(r => {
		      console.error('error in askaifaq: %O, %O', r.status, r.statusText)
		  })
	  },
	  saveFaq = ev => {
	      const fd = new FormData()
	      fd.append('question', question)
	      fd.append('mdanswer', answer)
	      fetch(`/api/integrationfaqs/${selectedInteg.provider_slug}/`, {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('post integrationfaqs: %O', json)
		      setRefreshFaqs(true)
		      setQuestion('')
		      setAnswer('')
		      setHtmlAnswer('')
		      setSuggestion('')
		  })
		  .catch(r => {
		      console.error('error in post integrationfaqs: %O, %O', r.status, r.statusText)
		  })
	  }
    return (
	<>
	    <div class="mb-2">
		<label class="form-label">Context for question?</label>
		<div class="input-group">
		    <input type="text"
			   class="form-control"
			   value={context}
			   onChange={ev => setContext(ev.target.value)}
			   disabled={!canEditContext}/>
		    <button type="button"
			    class="btn btn-outline-info"
			    onClick={ev => setCanEditContext(!canEditContext)}>
			{canEditContext ? "Lock" : "Edit"}
		    </button>
		</div>
	    </div>
	    <div class="mb-2">
		<div class="d-flex justify-content-between align-items-center gap-3">
		    <label class="form-label">Question?</label>
		</div>
		<div class="input-group">
		    <input type="text"
			   class="form-control"
			   value={question}
			   onChange={ev => setQuestion(ev.target.value)}/>
		    <button type="button"
			    class="btn btn-outline-info"
			    disabled={question.length == 0}
			    onClick={askQuestion}>Ask ChatGPT</button>
		</div>
	    </div>
	    <Tabs defaultActiveKey="markdown"
		  className="mb-2"
		  onSelect={showMarkdown}>
		<Tab eventKey="markdown" title="Answer">
		    <label class="form-label">Enter markdown here</label>
		    <textarea class="form-control mb-2"
			      rows="4"
			      value={answer}
			      onChange={ev => setAnswer(ev.target.value)}></textarea>
		    <div class="mb-2 text-end px-3">
			<button type="button"
				class="btn btn-primary"
				onClick={saveFaq}>Save</button>
		    </div>
		</Tab>
		<Tab eventKey="preview" title="Preview">
		    <div dangerouslySetInnerHTML={{__html: htmlAnswer}}></div>
		</Tab>
	    </Tabs>
	</>
    )
}

function FaqTab({integrations, selectedInteg, setCost})
{
    const [integFaqs, setIntegFaqs] = useState([]),
	  [integFaqsGeneric, setIntegFaqsGeneric] = useState([]),
	  [refreshFaqs, setRefreshFaqs] = useState(true)
    useEffect(() => {
	if ( selectedInteg ) {
	    fetch(`/api/integrationfaqs/${selectedInteg.provider_slug}`)
		.then(r => r.ok ? r.json() : Promise.reject(r))
		.then(json => {
		    setIntegFaqs(json[0].matches)
		    setIntegFaqsGeneric(json[1].matches || [])
		    setRefreshFaqs(false)
		})
		.catch(r => {
		    console.error('error in templatefaqs: %O, %O', r.status, r.statusText)
		})
	}
    }, [selectedInteg, refreshFaqs])
    return (
	<>
	    <Accordion>
		{integFaqs.map((integFaq, fi) => (
		    <Accordion.Item eventKey={fi}>
			<Accordion.Header>{integFaq.question}</Accordion.Header>
			<Accordion.Body>
			    <div dangerouslySetInnerHTML={{__html: integFaq.answer}}></div>
			    <div class="border-top pt-2 d-flex justify-content-between">
				<button type="button"
					class="btn btn-outline-info">Edit</button>
				<button type="button"
					class="btn btn-outline-danger">Delete</button>
			    </div>
			</Accordion.Body>
		    </Accordion.Item>
		))}
		<Accordion.Item eventKey={integFaqs.length}>
		    <Accordion.Header>Add a new FAQ</Accordion.Header>
		    <Accordion.Body><AddNewFaq integrations={integrations}
					       selectedInteg={selectedInteg}
					       setCost={setCost}/></Accordion.Body>
		</Accordion.Item>
	    </Accordion>
	</>
    )
}

function IntegrationTabs({integrations, selectedInteg, setCost})
{
    return (
	<Tabs defaultActiveKey="faqs"
	      className="mb-2">
	    <Tab eventKey="faqs" title="FAQs">
		<FaqTab integrations={integrations}
			selectedInteg={selectedInteg}
			setCost={setCost}/>
	    </Tab>
	</Tabs>
    )
}

export function Integrations({setCost})
{
    const [integrations, setIntegrations] = useState([]),
	  [selectedInteg, setSelectedInteg] = useState(null),
	  [nextIndex, setNextIndex] = useState(-1), // this is not an ID but an index
	  [prevIndex, setPrevIndex] = useState(-1), // this is not an ID but an index
	  onItemSelected = itemId => {
	      const found = integrations.find(x => x.id === itemId)
	      setSelectedInteg(found)
	  },
	  loadPrevInteg = ev => {
	      const prev = integrations[prevIndex]
	      setSelectedInteg(prev)
	  },
	  loadNextInteg = ev => {
	      const next = integrations[nextIndex]
	      setSelectedInteg(next)
	  }
    useEffect(() => {
	fetch('/api/integrations/')
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(json => {
		console.log('integrations response: %O', json)
		setIntegrations(json.integrations)
	    })
	    .catch(r => {
		console.error('error in integrations: %O, %O', r.status, r.statusText)
	    })
    }, [])
    useEffect(() => {
	if ( selectedInteg ) {
	    window.localStorage.setItem('selectedIntegId', selectedInteg.id)
	    const index = integrations.findIndex(x => x.id === selectedInteg.id)
	    if ( index === integrations.length - 1 ) {
		setNextIndex(0)
		setPrevIndex(index - 1)
	    } else if ( index === 0 ) {
		setNextIndex(index + 1)
		setPrevIndex(integrations.length - 1)
	    } else {
		setNextIndex(index + 1)
		setPrevIndex(index - 1)
	    }
	}
    }, [selectedInteg])
    return (
	<>
	    {integrations.length > 0 ? (
		<div class="mb-2">
		    <DropdownItemList label="Integrations"
				      items={integrations}
				      onItemSelected={onItemSelected}
				      itemFunc={x => <ItemDetail item={x}/>}/>
		</div>
	    ) : (
		<div class="spinner-border spinner-border-sm text-primary" role="status">
		    <span class="visually-hidden">Loading...</span>
		</div>
	    )}
	    {selectedInteg && (
		<>
		    <div class="d-flex justify-content-between align-items-center">
			<IconBxChevronLeft height="3rem"
					   width="3rem"
					   style={{cursor: 'pointer'}}
					   onClick={ev => loadPrevInteg()}/>
			<div class="d-flex gap-3 align-items-center">
			    <span>[{selectedInteg.id}]</span>
			    <h2 class="text-center">{selectedInteg.provider_name}</h2>
			</div>
			<IconBxChevronRight height="3rem"
					    width="3rem"
					    style={{cursor: 'pointer'}}
					    onClick={ev => loadNextInteg()}/>
		    </div>
		    <IntegrationTabs integrations={integrations}
				     selectedInteg={selectedInteg}
				     setCost={setCost}/>
		</>
	    )}
	</>
    )
}
