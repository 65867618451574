import React, { useRef, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Tooltip } from 'react-tooltip';
import { IconSearch, IconBookmarkCheck, IconArrowsMove, IconClose, IconDeleteOutline } from './icons';
import { Dropdown, Tab, Tabs, Form, Button, Modal } from 'react-bootstrap';
import * as inputs from './inputs';
import { makeid, get_access_token } from './utils';
import { ReactSortable } from "react-sortablejs";
import { EditText, EditTextarea } from 'react-edit-text';
import { LinkToggle, DropSearchMenu } from './dropdownwithmenu';
import './style.css'

const input_to_question = {
    'text': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'textarea': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'select': (question, setQuestion) => <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
    'radio': (question, setQuestion) => <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
    'checkbox': (question, setQuestion) => <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
    'file': (question, setQuestion) => <ShowFileQuestion question={question} setQuestion={setQuestion}/>,
    'date': (question, setQuestion) => <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
    'datetime': (question, setQuestion) => <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
    'datetime-local': (question, setQuestion) => <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
    'number': (question, setQuestion) => <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
    'tel': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'email': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'signature': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'url': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'yes_no': (question, setQuestion) => <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
    'time': (question, setQuestion) => <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
    'range': (question, setQuestion) => <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
    'range_slider': (question, setQuestion) => <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
}

const alts = [
    'TextInput',
    'NumberInput',
    'SingleChoice',
    'NumberChoice',
    'MultiChoice',
    'MultilineInput',
    'DateInput',
    'DropdownInput',
    'ContactInput',
    'DateDropdown',
    'YesNoChoice',
    'BoxChoice',
    'AddressInput',
    'PhoneInput',
    'EmailInput',
    'NameInput',
    'FileUpload',
    'CountrySelect',
    'ApptInput',
    'RangeSlider',
    'CurrencyInput',
    'SignatureInput',
    'ChoiceMatrix',
    'CompanyInput',
]

function typemap(type)
{
    return {
	'text': 'TextInput',
	'textarea': 'MultilineInput',
	'select': 'SingleChoice',
	'radio': 'SingleChoice',
	'checkbox': 'MultiChoice',
	'file': 'FileUpload',
	'date': 'DateDropdown',
	'datetime': 'DateInput',
	'datetime-local': 'DateInput',
	'number': 'NumberInput',
	'tel': 'PhoneInput',
	'email': 'EmailInput',
	'yes_no': 'YesNoChoice',
	'range': 'RangeSlider',
	'signature': 'SignatureInput',
	'NameInput': 'NameInput',
	'ContactInput': 'ContactInput',
	'MultiChoice': 'MultiChoice',
	'SingleChoice': 'SingleChoice',
	'YesNoChoice': 'YesNoChoice',
	'AddressInput': 'AddressInput',
	'CompanyInput': 'CompanyInput',
	'FileUpload': 'FileUpload',
	'SignatureInput': 'SignatureInput',
    }[type]
}

function question_to_step(question)
{
    let type = 'chosen_type' in question ? question.chosen_type : typemap(question.input_type)
    if ( ! type ) type = question.input_type
    switch(type) {
    case 'TextInput': return inputs.textinput(question)
    case 'NumberInput': return inputs.numberinput(question)
    case 'SingleChoice': return inputs.singlechoice(question)
    case 'NumberChoice': return inputs.numberchoice(question)
    case 'MultiChoice': return inputs.multichoice(question)
    case 'MultilineInput': return inputs.multilineinput(question)
    case 'DateInput': return inputs.dateinput(question)
    case 'DropdownInput': return inputs.dropdowninput(question)
    case 'ContactInput': return inputs.contactinput(question)
    case 'DateDropdown': return inputs.datedropdown(question)
    case 'YesNoChoice': return inputs.yesnochoice(question)
    case 'BoxChoice': return inputs.boxchoice(question)
    case 'AddressInput': return inputs.addressinput(question)
    case 'PhoneInput': return inputs.phoneinput(question)
    case 'EmailInput': return inputs.emailinput(question)
    case 'NameInput': return inputs.nameinput(question)
    case 'FileUpload': return inputs.fileupload(question)
    case 'CountrySelect': return inputs.countryselect(question)
    case 'ApptInput': return inputs.apptinput(question)
    case 'RangeSlider': return inputs.rangeslider(question)
    case 'CurrencyInput': return inputs.currencyinput(question)
    case 'SignatureInput': return inputs.signatureinput(question)
    case 'ChoiceMatrix': return inputs.choicematrix(question)
    case 'CompanyInput': return inputs.companyinput(question)
    default:
	console.log('unhandled case %O, %O: %O', type, question.input_type, question)
    }
}

function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

function ShowTextQuestion({question, setQuestion})
{
    const defmap = {
	'text': 'TextInput',
	'textarea': 'MultilineInput',
	'tel': 'PhoneInput',
	'email': 'EmailInput',
	'TextInput': 'TextInput',
	'NameInput': 'NameInput',
	'ContactInput': 'ContactInput',
	'CompanyInput': 'CompanyInput',
	'MultilineInput': 'MultilineInput',
	'AddressInput': 'AddressInput',
	'SignatureInput': 'SignatureInput'
    }
    return (
	<>
	    <div class="d-flex justify-content-between align-items-center">
		<div class="fw-bold">{question.input_type}</div>
		<select class="w-25 form-select"
			value={question.chosen_type || defmap[question.input_type]}
			onChange={ev => setQuestion(prev => ({...prev, chosen_type: ev.target.value}))}>
		    {alts.toSorted().map(name => <option>{name}</option>)}
		</select>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Label</span>
		<input type="text"
		       class="form-control"
		       value={question.question}
		       onChange={ev => setQuestion(prev => ({...prev, question: ev.target.value}))}/>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Description</span>
		<input type="text"
		       class="form-control"
		       value={question.description}
		       onChange={ev => setQuestion(prev => ({...prev, description: ev.target.value}))}/>
	    </div>
	</>
    )
}

function ShowChoiceQuestion({question, setQuestion})
{
    const defmap = {
	'select': 'SingleChoice',
	'radio': 'SingleChoice',
	'checkbox': 'MultiChoice',
	'yes_no': 'YesNoChoice',
	'AddressInput': 'AddressInput',
	'MultiChoice': 'MultiChoice',
	'SingleChoice': 'SingleChoice',
	'YesNoChoice': 'YesNoChoice',
	'NumberChoice': 'NumberChoice',
    }
    return (
	<>
	    <div class="d-flex justify-content-between align-items-center">
		<div class="fw-bold">{question.input_type}</div>
		<select class="w-25 form-select"
			value={question.chosen_type || defmap[question.input_type]}
			onChange={ev => setQuestion(prev => ({...prev, chosen_type: ev.target.value}))}>
		    {alts.toSorted().map(name => <option>{name}</option>)}
		</select>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Label</span>
		<input type="text"
		       class="mb-2 form-control"
		       value={question.question}
		       onChange={ev => setQuestion(prev => ({...prev, question: ev.target.value}))}/>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Description</span>
		<input type="text"
		       class="form-control"
		       value={question.description}
		       onChange={ev => setQuestion(prev => ({...prev, description: ev.target.value}))}/>
	    </div>
	</>
    )
}

function ShowNumberQuestion({question, setQuestion})
{
    const defmap = {
	'number': 'NumberInput',
	'range': 'RangeSlider',
	'NumberInput': 'NumberInput',
	'RangeSlider': 'RangeSlider',
    }
    return (
	<>
	    <div class="d-flex justify-content-between align-items-center">
		<div class="fw-bold">{question.input_type}</div>
		<select class="w-25 form-select"
			value={question.chosen_type || defmap[question.input_type]}
			onChange={ev => setQuestion(prev => ({...prev, chosen_type: ev.target.value}))}>
		    {alts.toSorted().map(name => <option>{name}</option>)}
		</select>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Label</span>
		<input type="text"
		       class="mb-2 form-control"
		       value={question.question}
		       onChange={ev => setQuestion(prev => ({...prev, question: ev.target.value}))}/>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Description</span>
		<input type="text"
		       class="form-control"
		       value={question.description}
		       onChange={ev => setQuestion(prev => ({...prev, description: ev.target.value}))}/>
	    </div>
	</>
    )
}

function ShowFileQuestion({question, setQuestion})
{
    const defmap = {
	'file': 'FileUpload',
	'FileUpload': 'FileUpload'
    }
    return (
	<>
	    <div class="d-flex justify-content-between align-items-center">
		<div class="fw-bold">{question.input_type}</div>
		<select class="w-25 form-select"
			value={question.chosen_type || defmap[question.input_type]}
			onChange={ev => setQuestion(prev => ({...prev, chosen_type: ev.target.value}))}>
		    {alts.toSorted().map(name => <option>{name}</option>)}
		</select>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Label</span>
		<input type="text"
		       class="form-control"
		       value={question.question}
		       onChange={ev => setQuestion(prev => ({...prev, question: ev.target.value}))}/>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Description</span>
		<input type="text"
		       class="form-control"
		       value={question.description}
		       onChange={ev => setQuestion(prev => ({...prev, description: ev.target.value}))}/>
	    </div>
	</>
    )
}

function ShowDateQuestion({question, setQuestion})
{
    const defmap = {
	'date': 'DateDropdown',
	'datetime-local': 'DateInput',
	'datetime': 'DateInput',
	'ApptInput': 'ApptInput',
	'DateInput': 'DateInput',
    }
    return (
	<>
	    <div class="d-flex justify-content-between align-items-center">
		<div class="fw-bold">{question.input_type}</div>
		<select class="w-25 form-select"
			value={question.chosen_type || defmap[question.input_type]}
			onChange={ev => setQuestion(prev => ({...prev, chosen_type: ev.target.value}))}>
		    {alts.toSorted().map(name => <option>{name}</option>)}
		</select>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Label</span>
		<input type="text"
		       class="form-control"
		       value={question.question}
		       onChange={ev => setQuestion(prev => ({...prev, question: ev.target.value}))}/>
	    </div>
	    <div class="input-group">
		<span class="input-group-text">Description</span>
		<input type="text"
		       class="form-control"
		       value={question.description}
		       onChange={ev => setQuestion(prev => ({...prev, description: ev.target.value}))}/>
	    </div>
	</>
    )
}

function ShowQuestion({question, setQuestion, combineDescr})
{
    const [value, setValue] = useState('')
    const childMap = {
	'text': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'textarea': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'select': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'radio': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'checkbox': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'file': <ShowFileQuestion question={question} setQuestion={setQuestion}/>,
	'date': <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
	'datetime': <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
	'datetime-local': <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
	'number': <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
	'tel': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'email': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'signature': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'url': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'yes_no': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'time': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'range': <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
	'range_slider': <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
	'NameInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'ContactInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'MultiChoice': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'SingleChoice': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'YesNoChoice': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'NumberChoice': <ShowChoiceQuestion question={question} setQuestion={setQuestion}/>,
	'AddressInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'CompanyInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'NumberInput': <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
	'RangeSlider': <ShowNumberQuestion question={question} setQuestion={setQuestion}/>,
	'ApptInput': <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
	'FileUpload': <ShowFileQuestion question={question} setQuestion={setQuestion}/>,
	'MultilineInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'TextInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'SignatureInput': <ShowTextQuestion question={question} setQuestion={setQuestion}/>,
	'DateInput': <ShowDateQuestion question={question} setQuestion={setQuestion}/>,
    }
    if ( question.input_type in childMap ) {
	return (
	    <>
		{childMap[question.input_type]}
		{(question.input_type === 'select' ||
		  question.input_type === 'radio' ||
		  question.input_type === 'checkbox' ||
		  question.chosen_type === 'SingleChoice' ||
		  question.input_type === 'SingleChoice' ||
		  question.chosen_type === 'MultiChoice' ||
		  question.input_type === 'MultiChoice' ||
		  question.chosen_type === 'DropdownInput' ||
		  question.input_type === 'DropdownInput' ||
		  question.chosen_type === 'BoxChoice' ||
		  question.chosen_type === 'NumberChoice' ||
		  question.input_type === 'BoxChoice') && (
		      <>
			  <textarea class="form-control"
				    value={question.options && question.options.constructor === Array ? question.options.map(opt=>opt.constructor===Object?opt.label:opt).join('\n') : ''}
				    rows={question.options ? question.options.length + 1 : 2}
				    onChange={ev => {
					setValue(ev.target.value)
					const options = ev.target.value.split(/\n+/g)
					      .filter(x => x.length > 0)
					      .map(x => x.trim())
					setQuestion(prev => ({...prev, options: options}))
				    }}/>
		      </>
		  )}
		{question.chosen_type === 'ContactInput' && (
		    <>
			<button type="button"
				className="btn btn-info"
				onClick={ev => combineDescr(question,
							    'What is your contact information?',
							    'tel', 'email')}>Combine</button>
			<br/>
			<span style={{fontSize: '0.75rem'}}>Phone and Email</span>
		    </>
		)}
		<div class="form-check">
		    <input class="form-check-input"
			   type="checkbox"
			   id="valueRequiredCheck"
			   checked={'valueRequired' in question ? question.valueRequired : true}
			   onChange={ev => {
			       setQuestion(prev => ({...prev, valueRequired: ev.target.checked}))
			   }}/>
		    <label class="form-check-label" for="valueRequiredCheck">
			value required
		    </label>
		</div>
	    </>
	)
    } else {
	console.warn('ignored input_type: %O [%O]', question.input_type, question)
	return (
	    <>
		<div class="fw-bold">{question.input_type}</div>
		<div class="input-group">
		    <span class="input-group-text">Label</span>
		    <input type="text"
			   class="form-control"
			   defaultValue={question.question}/>
		</div>
		<div class="input-group">
		    <span class="input-group-text">Description</span>
		    <input type="text"
			   class="form-control"
			   value={question.description}
			   onChange={ev => setQuestion(prev => ({...prev, description: ev.target.value}))}/>
		</div>
	    </>
	)
    }
}

function CombineQuestions({frmdef, setFrmdef})
{
    if ( ! frmdef ) return
    const [showModal, setShowModal] = useState(false),
	  [questions, setQuestions] = useState(frmdef.questions),
	  [selected, setSelected] = useState([]),
	  [combineTo, setCombineTo] = useState(''),
	  selectQuestion = ev => {
	      const index = selected.findIndex(x => x === ev.target.value)
	      if ( index === -1 ) {
		  if ( ev.target.checked ) {
		      setSelected(prev => [...prev, ev.target.value])
		  }
	      } else {
		  if ( ! ev.target.checked ) {
		      setSelected(prev => [...prev.slice(0, index), ...prev.slice(index+1)])
		  }
	      }
	  },
	  combineFunc = ev => {
	      setFrmdef(prev => {
		  const selQs = selected.map(qid => questions.find(q => q.id === qid)),
			ids = selQs.map(q => q.id),
			unselQs = questions.filter(q => !ids.includes(q.id)),
			newQ = {...selQs[0]},
			firstIndex = questions.findIndex(question => question.id === newQ.id)
		  newQ.rowLabels = selQs.map(question => question.question)
		  newQ.chosen_type = 'ChoiceMatrix'
		  return { ...prev,
			   questions: [...unselQs.slice(0, firstIndex),
				       newQ,
				       ...unselQs.slice(firstIndex+1)]}
	      })
	      setShowModal(false)
	  }
    return (
	<>
	    <a class="nolink"
	       onClick={ev => setShowModal(true)}><IconBookmarkCheck/></a>
	    <Modal show={showModal} backdrop="static" keyboard={false} onHide={ev => setShowModal(false)}>
		<Modal.Header closeButton>
		    <Modal.Title>Combine Questions</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		    <table className="table table-striped">
			<thead>
			    <tr>
				<th>#</th>
				<th>Type</th>
				<th>Question</th>
			    </tr>
			</thead>
			<tbody>
			    {questions.map((question, qi) => (
				<tr>
				    <td>
					<div class="form-check">
					    <input class="form-check-input"
						   type="checkbox"
						   value={question.id}
						   checked={selected.includes(question.id)}
						   onChange={selectQuestion}
						   id={"selectQuestion-" + qi}/>
					</div>
				    </td>
				    <td>
					<label htmlFor={"selectQuestion-" + qi}>{question.input_type}</label>
				    </td>
				    <td>
					<EditText inputClassName="form-control"
						  value={question.question}
						  onChange={ev => {
						      setQuestions(prev => [...prev.slice(0, qi),
									    {...prev[qi], question: ev.target.value},
									    ...prev.slice(qi+1)])
						  }}></EditText>
					<label htmlFor={"selectQuestion-" + qi}
					       className="form-text">{question.description}</label>
					{question.options && (
					    <label htmlFor={"selectQuestion-" + qi}
						   className="form-text">{JSON.stringify(question.options)}</label>
					)}
				    </td>
				</tr>
			    ))}
			</tbody>
		    </table>
		    <div className="mb-2">
			<div class="form-label">Combine as</div>
			<select class="form-select"
				value={combineTo}
				onChange={ev => setCombineTo(ev.target.value)}>
			    <option value="">-- Select --</option>
			    <option>ChoiceMatrix</option>
			</select>
		    </div>
		</Modal.Body>
		<Modal.Footer>
		    <Button variant="secondary" onClick={ev => setShowModal(false)}>
			Close
		    </Button>
		    <Button variant="primary"
			    className="d-flex align-items-center"
			    disabled={!(selected.length > 0 && combineTo.length > 0)}
			    onClick={combineFunc}>
			Combine
		    </Button>
		</Modal.Footer>
	    </Modal>
	</>
    )
}

function PopularBlock({setTextValue, disableBtn, setDisableBtn})
{
    const [popular, setPopular] = useState([]),
	  [selIndex, setSelIndex] = useState(-1),
	  [selForm, setSelForm] = useState(null)
    useEffect(() => {
	fetch('/assets/data/popular-fixed.json')
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(json => {
		console.log('response from popular: %O', json)
		let arr = []
		json.forEach(cat => {
		    if ( 'subcats' in cat ) {
			cat.subcats.forEach(subcat => arr.push(...subcat.pages))
		    } else if ( 'pages' in cat ) {
			arr.push(...cat.pages)
		    }
		})
		setPopular(arr.toSorted((a, b) => Number(b.count) - Number(a.count)))
	    })
	    .catch(r => {
		setDisableBtn(false)
		console.error('error in operation: %O, %O', r.status, r.statusText)
		r.text().then(text => console.error('detail: %O', text))
	    })
    }, [])
    return popular && popular.length > 0 ? (
	<>
	    <div class="input-group my-3">
		<select class="form-select"
			value={selIndex}
			disabled={disableBtn}
			onChange={ev => {
			    setSelIndex(+ev.target.value)
			    setSelForm(popular[+ev.target.value])
			    setTextValue(popular[+ev.target.value].name)
			}}>
		    <option value={-1}>-- Select --</option>
		    {popular.map((form, index) => <option value={index}>{form.name} [{form.count}]</option>)}
		</select>
	    </div>
	    {selForm && <div class="mb-3"><a href={selForm.url} target="_blank">{selForm.name}</a></div>}
	</>
    ) : <div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div>
}

function SearchMatch({match})
{
    return (
	<Dropdown>
	    <Dropdown.Toggle as={LinkToggle}>
		<span class="me-1">{match.term}</span>
		<span style={{color: 'var(--bs-success)', fontSize: '0.8rem'}}>[{match.matches.length}]</span>
	    </Dropdown.Toggle>
	    <Dropdown.Menu as={DropSearchMenu}
			   style={{maxHeight: '250px',
				   overflowY: 'auto',
				   overflowX: 'hidden'}}>
		{match.matches.map((tform, ti) => <Dropdown.Item eventKey={ti+1}>{tform.title}</Dropdown.Item>)}
	    </Dropdown.Menu>
	</Dropdown>
    )
}

function AskFormBlock({setTitle, setDescr, setShowLoading, setFrmdef, setCreatedFrm, setCost, setMarkdown,
		       textValue, setTextValue,
		       disableBtn, setDisableBtn})
{
    const [ignoreCache, setIgnoreCache] = useState(false),
	  [moreInfo, setMoreInfo] = useState(''),
	  [promptFile, setPromptFile] = useState('prompt-2.txt'),
	  [searchCounts, setSearchCounts] = useState([]),
	  [showCountSpinner, setShowCountSpinner] = useState(false),
	  askCounts = () => {
	      setSearchCounts([])
	      setShowCountSpinner(true)
	      const fdsearch = new FormData()
	      fdsearch.append('title', textValue)
	      fetch('/api/askcounts/', {
		  method: 'POST',
		  body: fdsearch,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('askcounts response: %O', json)
		      setSearchCounts(json.results)
		      setShowCountSpinner(false)
		  })
		  .catch(r => {
		      console.error('error in askcounts: %O, %O', r.status, r.statusText)
		      r.text().then(text => console.error('detail: %O', text))
		      setShowCountSpinner(false)
		  })
	  },
	  askAiForm = ev => {
	      setDisableBtn(true)
	      setTitle('')
	      setDescr('')
	      setFrmdef({})
	      setCreatedFrm(null)
	      setShowLoading(true)
	      setCost('')
	      const fd = new FormData()
	      fd.append('frmname', textValue)
	      fd.append('moreinfo', moreInfo)
	      fd.append('prompt-file', promptFile)
	      fd.append('ignore-cache', ignoreCache)
	      fetch('/api/askaiform/', {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      setDisableBtn(false)
		      setTitle(json.title)
		      setDescr(json.message.content)
		      json.frmdef.questions.forEach(question => question.id = makeid(6))
		      setFrmdef(json.frmdef)
		      setShowLoading(false)
		      setCost(json.json.input_cost + json.json.output_cost + ' &cent;')
		      setMarkdown(json.markdown.content)
		      console.log('response from askaiform: %O', json)
		  })
		  .catch(r => {
		      setDisableBtn(false)
		      setShowLoading(false)
		      console.error('error in operation: %O, %O', r.status, r.statusText)
		      r.text().then(text => console.error('detail: %O', text))
		  })
	  }
    return (
	<>
	    <div class="d-flex align-items-start gap-3">
		<div class="mb-2 w-100">
		    <label class="form-label">Form name</label>
		    <div class="input-group">
			<input type="text"
			       class="form-control"
			       placeholder='enter form slug e.g. "business registration form"'
			       value={textValue}
			       disabled={disableBtn}
			       onChange={ev => setTextValue(ev.target.value)}/>
			<button type="button"
				class="btn btn-outline-secondary"
				disabled={!textValue}
				onClick={askCounts}>
			    {showCountSpinner ?
			     <div class="spinner-border spinner-border-sm text-primary"></div> :
			     <IconSearch/>}
			</button>
		    </div>
		    <div class="mt-2 form-check">
			<input class="form-check-input"
			       type="checkbox"
			       checked={ignoreCache}
			       id="ignoreCacheCheckbox"
			       onChange={ev => setIgnoreCache(ev.target.checked)}/>
			<label class="form-check-label"
			       htmlFor="ignoreCacheCheckbox">
			    Ignore cache
			</label>
		    </div>
		</div>
		<div class="mb-2 w-75">
		    <label class="form-label">Prompt</label>
		    <select class="form-select"
			    value={promptFile}
			    onChange={ev => setPromptFile(ev.target.value)}>
			<option>prompt-1.txt</option>
			<option>prompt-2.txt</option>
		    </select>
		</div>
		<div class="mb-2 w-50">
		    <label class="form-label">More prompt text, if any.</label>
		    <input type="text"
			   class="form-control"
			   placeholder='enter more info, if any, here. Free form text.'
			   value={moreInfo}
			   disabled={disableBtn}
			   onChange={ev => setMoreInfo(ev.target.value)}/>
		    <div class="mt-2 d-flex justify-content-end">
			<button class="mb-2 btn btn-outline-secondary text-end"
				type="button"
				disabled={disableBtn}
				onClick={askAiForm}>Ask</button>
		    </div>
		</div>
	    </div>
	    <div class="d-flex gap-2 mb-3">
		{searchCounts.map((match, mi) => <SearchMatch match={match}/>)}
	    </div>
	</>
    )
}

function TitleBlock({title, saveDefs, loadDefs, createForm})
{
    return (
	<>
	    <div class="d-flex justify-content-between">
		<h3 class="">{title}</h3>
		<div>
		    <button type="button"
			    class="btn btn-info me-2"
			    onClick={saveDefs}>Save</button>
		    <button type="button"
			    class="btn btn-warning me-2"
			    onClick={loadDefs}>Load</button>
		    <button type="button"
			    class="btn btn-primary"
			    onClick={createForm}>Create</button>
		</div>
	    </div>
	</>
    )
}

function FormDefiner({title, descr, setDescr, frmdef, setFrmdef, createdFrm, setCreatedFrm, setDisableBtn})
{
    const tooltipRef = useRef(null),
	  saveDefs = ev => {
	      window.localStorage.setItem(title, JSON.stringify(frmdef))
	  },
	  loadDefs = ev => {
	      const x = window.localStorage.getItem(title)
	      if ( x ) setFrmdef(JSON.parse(x))
	  },
	  createForm = ev => {
	      let frm = {
		  'title': title,
		  'integrations': null,
		  'options': {
		      'cookieConsent': true,
		      'desktopLayout': 'fullscreen',
		      'mobileLayout': 'fullscreen',
		  },
		  'steps': [{
		      'btnColor': "chocolate",
		      'btnLabel': "Next Step",
		      'displayStr': "Welcome Step",
		      'fieldDescr': frmdef['introduction']['text'],
		      'fieldLabel': title,
		      'icon': "diamond",
		      'mainImage': null,
		      'mainImageName': null,
		      'stepId': makeid(8),
		      'type': "WelcomeStep"
		  }, {
		      "url": "https://teramine.com",
		      "icon":"done_all",
		      "type":"ThankyouStep",
		      "stepId":makeid(8),
		      "showUrl":true,
		      "btnColor":"darkmagenta",
		      "urlLabel": "Click here to continue",
		      "displayStr":"Thank You",
		      "headerLabel":"Thank You",
		      "showSummary":false,
		      "summaryFormat":"",
		      "subheaderLabel": frmdef['closing']['text']
		  }]
	      }
	      console.log('frmdef = %O',frmdef)
	      frmdef.questions.forEach(question => {
		  const step = question_to_step(question)
		  if ( step ) {
		      // override fieldDescr here since it is common for all steps
		      if ( question.description ) step.fieldDescr = question.description
		      if ( 'valueRequired' in question ) step.valueRequired = question.valueRequired
		      frm.steps.splice(frm.steps.length - 1, 0, step)
		  }
	      })
	      console.log('frm = %O', frm)
	      fetch('/api/createform/', {
		  method: 'POST',
		  body: JSON.stringify(frm)
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('response from createform: %O', json)
		      setCreatedFrm(json)
		  })
		  .catch(r => {
		      setDisableBtn(false)
		      console.error('error in operation: %O, %O', r.status, r.statusText)
		      r.text().then(text => console.error('detail: %O', text))
		  })
	  },
	  setQuestion = (func, qi) => {
	      const after = func(frmdef.questions[qi])
	      setFrmdef(prev => ({...prev,
				  questions: [...frmdef.questions.slice(0, qi),
					      after,
					      ...frmdef.questions.slice(qi+1)]}))
	  },
	  doDelete = qno => {
	      setFrmdef(prev => ({...prev,
				  questions: [...frmdef.questions.slice(0, qno),
					      ...frmdef.questions.slice(qno+1)]}))
	  },
	  combineDescr = (question, qtext, ...args) => {
	      if ( ! frmdef || frmdef.questions.length == 0 ) return
	      const index = frmdef.questions.findIndex(q => q.id === question.id),
		    targets = frmdef.questions.filter(q => args.includes(q.input_type))
	      if ( targets.length === 0 ) return
	      const updated = ({...question,
				question: qtext,
				description: targets.map(t => t.description).join(' ')})
	      setFrmdef(prev => ({...prev, questions: [...prev.questions.slice(0, index),
						       updated,
						       ...prev.questions.slice(index+1)]}))
	  }
    return title && (
	<div class="mb-2">
	    {title && <TitleBlock title={title}
				  saveDefs={saveDefs}
				  loadDefs={loadDefs}
				  createForm={createForm}/>}
	    {createdFrm && (
		<div class="alert alert-success fade show">
		    <div class="d-flex justify-content-between">
			<span>{createdFrm.title}</span>
			<a href={"https://teramine.com/form-builder/" + createdFrm.frmid}
			   className="alert-link">
			    {createdFrm.frmid}
			</a>
		    </div>
		</div>
	    )}
	    {descr && (
		<>
		    <div class="">
			<textarea class="form-control"
				  value={descr}
				  rows="3"
				  onChange={ev => setDescr(ev.target.value)}/>
		    </div>
		</>
	    )}
	    <ul class="list-group list-group-numbered">
		{frmdef.introduction && (
		    <li class="list-group-item d-flex justify-content-between align-items-start">
			<div class="ms-2 w-100">
			    <div class="fw-bold">WelcomeStep</div>
			    <textarea class="form-control"
				      rows="2"
				      value={frmdef.introduction.text}/>
			</div>
			<span class="badge bg-primary rounded-pill"></span>
		    </li>
		)}
		{console.log('frmdef = %O', frmdef)}
		{frmdef.questions && (
		    <>
			<div className="rounded border p-2">
			    <CombineQuestions frmdef={frmdef}
					      setFrmdef={setFrmdef}/>
			</div>
			<ReactSortable list={frmdef.questions}
				       setList={questions => {
					   setFrmdef(prev => ({...prev, questions: questions}))
				       }}
				       handle=".handle"
				       animation={250}>
			    {frmdef.questions && frmdef.questions.map((question, qi) => (
				<li className="list-group-item d-flex justify-content-between align-items-start"
				    style={{backgroundColor: (qi%2==1?"#ddd":"#fff")}}>
				    <div class="ms-2 w-100">
					<div>
					    <IconArrowsMove className="handle me-3"/>
					    <a style={{cursor: 'pointer'}}
					       class="text-danger"
					       data-question-no={qi}
					       data-tooltip-id="delete-tooltip"><IconClose/></a>
					    <ShowQuestion question={question}
							  setQuestion={func => setQuestion(func, qi)}
							  combineDescr={combineDescr}/>
					</div>
				    </div>
				    <span class="badge bg-primary rounded-pill"></span>
				</li>
			    ))}
			</ReactSortable>
		    </>
		)}
		<Tooltip id="delete-tooltip"
			 ref={tooltipRef}
			 style={{padding: 0, zIndex: 100}}
			 clickable
			 openOnClick={true}
			 render={({content, activeAnchor}) => {
			     const qno = Number(activeAnchor?.getAttribute('data-question-no'))
			     return (
				 <div class="p-4 rounded"
				      style={{backgroundColor: '#ddd', color: '#000'}}>
				     <div class="mb-2">
					 <div>Are you sure you want to delete this question?</div>
				     </div>
				     <div class="d-flex justify-content-between">
					 <button class="btn btn-secondary"
						 onClick={ev => tooltipRef.current?.close()}>
					     <IconClose/> Close
					 </button>
					 <button class="btn btn-danger"
						 onClick={ev => {
						     doDelete(qno)
						     tooltipRef.current?.close()
						 }}>
					     <IconDeleteOutline/> Delete
					 </button>
				     </div>
				 </div>
			     )
			 }}/>
		{frmdef.closing && (
		    <li class="list-group-item d-flex justify-content-between align-items-start">
			<div class="ms-2 w-100">
			    <div class="fw-bold">ThankYou</div>
			    <textarea class="form-control"
				      rows="2"
				      value={frmdef.closing.text}/>
			</div>
			<span class="badge bg-primary rounded-pill"></span>
		    </li>
		)}
	    </ul>
	</div>
    )
}

function FormContent({markdown})
{
    const [htmlContent, setHtmlContent] = useState('')
    useEffect(() => {
	if ( markdown ) {
	    const fd = new FormData()
	    fd.append('markdown', markdown)
	    fetch('/api/md2html/', {
		method: 'POST',
		body: fd,
		headers: {
                    'X-CSRFToken': getCookieValue('csrftoken'),
		}
	    })
		.then(r => r.ok ? r.json() : Promise.reject(r))
		.then(json => {
		    setHtmlContent(json.html)
		})
		.catch(r => {
		    console.error('error in md2html: %O, %O', r.status, r.statusText)
		})
	}
    }, [markdown])
    return (
	<>
	    <div dangerouslySetInnerHTML={{__html: htmlContent}}></div>
	</>
    )
}

export function AskAI({setCost})
{
    const [textValue, setTextValue] = useState(window.localStorage.getItem('searchValue')),
	  [disableBtn, setDisableBtn] = useState(false),
	  [title, setTitle] = useState(''),
	  [descr, setDescr] = useState(''),
	  [frmdef, setFrmdef] = useState({}),
	  [createdFrm, setCreatedFrm] = useState(null),
	  [markdown, setMarkdown] = useState(''),
	  [showLoading, setShowLoading] = useState(false)
    useEffect(() => {
	window.localStorage.setItem('searchValue', textValue)
    }, [textValue])
    return (
	<>
	    <PopularBlock setTextValue={setTextValue}
			  disableBtn={disableBtn}
			  setDisableBtn={setDisableBtn}/>
	    <AskFormBlock setTitle={setTitle}
			  setDescr={setDescr}
			  setShowLoading={setShowLoading}
			  setFrmdef={setFrmdef}
			  setCreatedFrm={setCreatedFrm}
			  setCost={setCost}
			  setMarkdown={setMarkdown}
			  textValue={textValue}
			  setTextValue={setTextValue}
			  disableBtn={disableBtn}
			  setDisableBtn={setDisableBtn}/>
	    {showLoading && (
		<div class="d-flex justify-content-center">
		    <div class="p-bar">
			<div class="p-bar-value"></div>
		    </div>
		</div>
	    )}
	    {Object.keys(frmdef).length > 0 && (
		<Tabs defaultActiveKey="frmdef"
		      className="mb-3">
		    <Tab eventKey="frmdef" title="Form">
			<FormDefiner title={title}
				     descr={descr}
				     setDescr={setDescr}
				     frmdef={frmdef}
				     setFrmdef={setFrmdef}
				     createdFrm={createdFrm}
				     setCreatedFrm={setCreatedFrm}
				     setDisableBtn={setDisableBtn}/>
		    </Tab>
		    <Tab eventKey="content" title="Content">
			<FormContent markdown={markdown}/>
		    </Tab>
		    <Tab eventKey="messages" title="Messages">
			No messages to show.
		    </Tab>
		</Tabs>
	    )}
	</>
    )
}
